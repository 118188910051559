import React from 'react'
import { useNavigate } from 'react-router-dom';
import CreditScoreIll from "../assets/128609248_Emoji Satisfaction Meter Flat.svg";
import UploadDocumentIll from "../assets/6261715_673.svg";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className=' container'>
      <div className='min-h-screen flex justify-center items-center' >
        <div className=' grid grid-cols-2 w-full justify-items-center'>
          <div className=' flex flex-col justify-center items-center gap-8 max-w-[500px] w-full border border-slate-300 rounded-2xl hover:shadow-xl transition-all duration-300 p-5'>
            <div className=' text-3xl font-medium'>Get Score</div>
            <div className=' h-80 w-80 flex justify-center items-center'>
              <img src={CreditScoreIll} className=' max-w-[300px] w-full aspect-square' alt="" />
            </div>
            <button
              className={`text-sm text-white rounded-lg px-3 py-2 bg-indigo-500 hover:bg-indigo-600 transition-all duration-300`}
              onClick={() => navigate('/scoring-model')}
            >
              <svg width="20" height="20" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8672 3.93372L29.0001 15.2L16.8672 26.4663" stroke="white" strokeWidth="4.33319" strokeLinecap="square" strokeLinejoin="bevel" />
                <path d="M3 14.7357H27.2659" stroke="white" strokeWidth="4.33319" strokeLinecap="square" strokeLinejoin="bevel" />
              </svg>
            </button>
          </div>

          <div className=' flex flex-col justify-center items-center gap-8 max-w-[500px] w-full border border-slate-300 rounded-2xl hover:shadow-xl transition-all duration-300 p-5'>
            <div className='text-3xl font-medium'>Get Report</div>
            <div className=' h-80 w-80 flex justify-center items-center'>
              <img src={UploadDocumentIll} className=' max-w-[200px] w-full aspect-square' alt="" />
            </div>
            <button
              className={`text-sm text-white rounded-lg px-3 py-2 bg-indigo-500 hover:bg-indigo-600 transition-all duration-300`}
              onClick={() => navigate('/document-upload')}
            >
              <svg width="20" height="20" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8672 3.93372L29.0001 15.2L16.8672 26.4663" stroke="white" strokeWidth="4.33319" strokeLinecap="square" strokeLinejoin="bevel" />
                <path d="M3 14.7357H27.2659" stroke="white" strokeWidth="4.33319" strokeLinecap="square" strokeLinejoin="bevel" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home