import React, { useState } from 'react'

const RenderDropDowns = ({ files }) => {
  const defaultDropdownValues = {
    industryOutlook: 'favourable',
    demandSupplyGap: 'satisfactoryDemand',
    productionStrength: 'goodProduction',
    marketingStrength: 'goodMarketing',
    expectedSalesGrowth: '5To10Percent3Years',
    constitutionExistence: 'publicLtdListed',
    managementEducationExperience: 'trainedOver3Years',
    managementIntegrityCommitment: 'beyondDoubt',
    successionPlanning: 'experiencedManagement',
    collateralsCoverage: 'coverageAbove100',
    repaymentPeriod: 'repayment3To5Years',
    creditHistory: 'noDefault3Years',
    complianceLegalRegulatory: 'fullyComplied',
    governmentApprovals: 'allInPlace',
    accountingTransparency: 'noAdverse',
    // Add default values for other dropdowns here
  };

  const initialFormData = {
    dropdownValues: defaultDropdownValues, // Merged dropdown values
  };

  const [formData, setFormData] = useState(initialFormData);

  console.log("formData",formData)

  const renderDropdown = (label, name, options) => (
    <div className="dropdown-group mb-4">
      <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
        {label}:
      </label>
      <select
        className="dropdown shadow border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
        name={name}
        id={name}
        value={formData.dropdownValues[name]}
        onChange={(e) => handleChange(e, name)}
        aria-label={label}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  const handleChange = (e, subCategory) => {
    setFormData({
      ...formData,
      dropdownValues: {
        ...formData.dropdownValues,
        [subCategory]: e.target.value,
      },
    });
  };

  return (
    <div className=' container'>
      {
        files.length > 0 &&
        <div>
          {/* Industry Outlook */}
          {renderDropdown(
            'Industry Outlook',
            'industryOutlook',
            [
              { value: 'unfavourable', label: 'Unfavourable' },
              { value: 'marginallyUnfavorable', label: 'Marginally Unfavorable' },
              { value: 'marginallyFavorable', label: 'Marginally Favorable' },
              { value: 'favourable', label: 'Favourable' },
              { value: 'favourableExportOriented', label: 'Favourable & export-oriented' },
            ]
          )}

          {/* Demand Supply Gap */}
          {renderDropdown(
            'Demand Supply Gap',
            'demandSupplyGap',
            [
              { value: 'excessSupply', label: 'Excess supply than demand' },
              { value: 'marginalDemand', label: 'Marginal demand' },
              { value: 'satisfactoryDemand', label: 'Satisfactory demand' },
              { value: 'goodDemand', label: 'Good Demand' },
              { value: 'scarcity', label: 'Scarcity of Product' },
            ]
          )}

          {/* Production Strength */}
          {renderDropdown(
            'Production Strength',
            'productionStrength',
            [
              { value: 'poorProduction', label: 'Poor production strength' },
              { value: 'inadequateProduction', label: 'Inadequate production strength' },
              { value: 'satisfactoryProduction', label: 'Satisfactory production strength' },
              { value: 'goodProduction', label: 'Good production strength' },
              { value: 'dominance', label: 'Production dominance' },
            ]
          )}

          {/* Marketing Strength */}
          {renderDropdown(
            'Marketing Strength',
            'marketingStrength',
            [
              { value: 'poorMarketing', label: 'Poor customer base and market network' },
              { value: 'inadequateMarketing', label: 'Inadequate customer base and market network' },
              { value: 'satisfactoryMarketing', label: 'Satisfactory customer base and market network' },
              { value: 'goodMarketing', label: 'Good customer base and market network' },
              { value: 'sellersMarket', label: 'Sellers Market' },
            ]
          )}

          {/* Expected Sales Growth */}
          {renderDropdown(
            'Expected Sales Growth',
            'expectedSalesGrowth',
            [
              { value: 'negativeGrowth', label: '< 5% or Negative for consecutively 2-3 years' },
              { value: 'upto5Percent1Year', label: 'up to 5% for 1 year out of 3 years' },
              { value: '5Percent2Years', label: '5 % for consecutively 2 years out of past 3 years' },
              { value: '5To10Percent2Years', label: '5-10% for consecutively in last 2 years out of past 3 years' },
              { value: '5To10Percent3Years', label: '5-10% for consecutively in last 3 years out of past 3 years' },
            ]
          )}

          {/* Constitution & Existence of the Applicant */}
          {renderDropdown(
            'Constitution & Existence of the Applicant',
            'constitutionExistence',
            [
              { value: 'newFirm', label: 'New Proprietorship /Partnership firm/HUF' },
              { value: 'establishedFirm1Year', label: 'Proprietorship /Partnership/Pvt. Ltd firm more than a year old' },
              { value: 'pvtLtd3Years', label: 'Pvt. Ltd company with experience of 3 years' },
              { value: 'ltd3YearsNotListed', label: 'Ltd company with exp. of 3 years but not listed' },
              { value: 'publicLtdListed', label: 'Listed public ltd company' },
            ]
          )}

          {/* Management Education Experience */}
          {renderDropdown(
            'Management Education & Experience',
            'managementEducationExperience',
            [
              { value: 'untrainedStartup', label: 'Not trained and fresh start-up' },
              { value: 'trainedFresh', label: 'Trained but fresh' },
              { value: 'trainedUnder1Year', label: 'Trained with experience of less than a year' },
              { value: 'trainedOver1Year', label: 'Trained with experience of more than 1 year' },
              { value: 'trainedOver3Years', label: 'Trained with experience of more than 3 years' },
            ]
          )}

          {/* Management Integrity Commitment */}
          {renderDropdown(
            'Management Integrity & Commitment',
            'managementIntegrityCommitment',
            [
              { value: 'poorDefaulter', label: 'Poor/willful defaulter' },
              { value: 'marginallyAcceptable', label: 'Marginally acceptable' },
              { value: 'satisfactory', label: 'Satisfactory' },
              { value: 'reliable', label: 'Reliable' },
              { value: 'beyondDoubt', label: 'Beyond doubt' },
            ]
          )}

          {/* Succession Planning */}
          {renderDropdown(
            'Succession Planning',
            'successionPlanning',
            [
              { value: 'notAvailable', label: 'Not available' },
              { value: 'foundFromEmployee', label: 'May be found from an existing employee' },
              { value: 'availableNeedTraining', label: 'Available next line management but need training' },
              { value: 'experiencedManagement', label: 'Experienced next line management' },
            ]
          )}

          {/* Collaterals Coverage */}
          {renderDropdown(
            'Collaterals Coverage%',
            'collateralsCoverage',
            [
              { value: 'coverageBelow10', label: '≤10' },
              { value: 'coverage10To40', label: '10.0-≤40.0' },
              { value: 'coverage40To75', label: '40.01-≤ 75.0' },
              { value: 'coverage75To100', label: '75.0-≤ 100.0' },
              { value: 'coverageAbove100', label: '>100%' },
            ]
          )}

          {/* Repayment Period */}
          {renderDropdown(
            'Repayment Period',
            'repaymentPeriod',
            [
              { value: 'repaymentOver7Years', label: '≥ 7 years' },
              { value: 'repayment6To7Years', label: '6.1-≤7 years' },
              { value: 'repayment5To6Years', label: '5.1-≤6 years' },
              { value: 'repayment3To5Years', label: '3.1-≤5 years' },
              { value: 'repaymentUnder3Years', label: '≤ 3 years' },
            ]
          )}

          {/* Credit History */}
          {renderDropdown(
            'Credit History',
            'creditHistory',
            [
              { value: 'pastDueOver3Months', label: 'Past due over 3 months' },
              { value: 'occasionallyIrregular', label: 'Occasionally Irregular due to interest but repaid' },
              { value: 'noIrregularity1Year', label: 'No irregularity in the past year' },
              { value: 'noDefault3Years', label: 'No Past due/default in past 3 years' },
              { value: 'noDefaultRelatedParty', label: 'No past due/default in any related party' },
            ]
          )}

          {/* Compliance Legal Regulatory */}
          {renderDropdown(
            'Compliance Legal Regulatory',
            'complianceLegalRegulatory',
            [
              { value: 'noSecurityDocument', label: 'Not created security document as per law' },
              { value: 'compliedButGovtDue', label: 'Complied in Creation of securities but Govt. due' },
              { value: 'complied', label: 'Complied' },
              { value: 'fullyComplied', label: 'Fully Complied' },
            ]
          )}

          {/* Government Approvals */}
          {renderDropdown(
            'Government Approvals',
            'governmentApprovals',
            [
              { value: 'approvalsPending', label: 'Yet to obtain approvals' },
              { value: 'majorityPending', label: 'Majority left to start the business' },
              { value: 'minorPending', label: 'Some left which does not affect to start the operation' },
              { value: 'majorObtained', label: 'Major approvals were obtained.' },
              { value: 'allInPlace', label: 'All approval is in place' },
            ]
          )}

          {/* Accounting Transparency */}
          {renderDropdown(
            'Accounting Transparency',
            'accountingTransparency',
            [
              { value: 'adverseImpactAbove20', label: 'Adverse observation having an impact on TNW by 20% or above' },
              { value: 'adverseImpact10To20', label: 'Adverse observation having an impact on TNW by 10% to 20%' },
              { value: 'adverseImpactUpto10', label: 'Adverse observation having an impact on TNW up to 10%' },
              { value: 'minorAdverseComment', label: 'Some adverse comment but does not affect' },
              { value: 'noAdverse', label: 'No adverse observation' },
            ]
          )}
        </div>
      }
    </div>
  )
}

export default RenderDropDowns