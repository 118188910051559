import React, { useRef, useState } from 'react';
import uploadIcon from '../assets/feather_upload-cloud.svg';
import RenderDropDowns from '../partials/document-upload/RenderDropDowns';
// import ExcelIcon from "../../images/bi_file-earmark-image.svg"

const UploadPricing = () => {
  const [fileType, setFileType] = useState(".xlsx,.xls,.csv");

  const [files, setFiles] = useState([]);
  // console.log("file : ", files[0])

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFiles([...files, ...e.target.files]);
    }
  };

  // Create a reference to the hidden file input element
  const hiddenFileInputRef = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInputRef.current.click();
  };

  const handleUploadFile = async () => {
    const fileToUpload = new FormData();
    fileToUpload.append("file", files[0]);
    // const { data, error } = await uploadExcel(userData, fileToUpload);

    // if (data && data.success) {
    //   setFiles([]);
    //   setModalOpen(true);
    // }
    // if (error) {
    //   window.alert(error?.response?.data?.message);
    // }
  };

  return (
    <div className="flex overflow-hidden">
      {/* Content area */}
      <div className="relative flex h-screen flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <main className="md:mb-52">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1 text-center">Upload Document ✨</h1>
          </div>

          <div className=' py-4 flex justify-center items-center'>
            <div className="dropdown-group mb-4">
              <label htmlFor={"fileType"} className="block text-gray-700 text-sm font-bold mb-2">
                Select File Type
              </label>
              <select
                className="dropdown shadow border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                name={"fileType"}
                id={"fileType"}
                value={fileType}
                onChange={(e) => setFileType(e?.target?.value)}
              >
                <option value={".xlsx,.xls,.csv"}>Balance Sheet (In the form of excel)</option>
                <option value={".pdf"}>Rubix PDF</option>
              </select>
            </div>
          </div>

          <div className="mx-8 p-4 bg-white">
            {files.length === 0 &&
              <>
                <p className="text-base md:text-xl text-black text-center pt-10 md:pt-16">Please select file</p>
                <p className="text-sm text-[#00000099] text-center mt-2 md:mt-4">document should follow the guidelines, which was provided by tech team</p>

                <div className="flex flex-col justify-center items-center border-2 border-dashed rounded-lg max-w-[530px] mx-auto mt-10 mb-10 md:mb-20 py-14 px-2 text-center">
                  <div className="mb-6">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <p className="text-base text-black">Select a file</p>
                  <p className="text-xs text-[#00000066] mb-6">.pdf file size no more than 10MB</p>
                  <button
                    className={`border-[1px] border-[#E2E8F0] rounded-xl px-3 py-2 ${files?.length === 1 && "cursor-not-allowed"}`}
                    onClick={handleClick}
                    disabled={files?.length === 1}
                  >
                    Select File
                  </button>
                  <input
                    // multiple
                    type="file"
                    onChange={handleFileChange}
                    ref={hiddenFileInputRef}
                    style={{ display: "none" }}
                    accept={fileType}
                  />
                </div>
              </>
            }


            {files.length > 0 &&
              <div className="max-w-[530px] mx-auto mb-10">
                <p className="text-sm text-black mb-4">File Added</p>

                {files?.map((item, index) => {
                  return (
                    <div key={index} className="flex space-x-2 items-center px-3 py-4">
                      {/* <div><img src={ExcelIcon} alt="" /></div> */}
                      <div className="grow flex justify-between items-center gap-1">
                        <div className="text-xs text-black">{item && `${item.name}`}</div>
                        <div className="text-xs text-[#000000B2]">{item && `${(item.size / (1024 ** 2)).toFixed(2)} MB`}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            }
          </div>

          {/* Dropdowns */}
          <RenderDropDowns
            files={files}
          />

          <div className="flex justify-end items-center space-x-2 mx-8 py-5 sm:px-14 px-4 border-t-[1px] border-[#0000001A] bg-[#FBFDFE]">
            <button
              className="text-sm text-black rounded-lg px-3 py-2 bg-white"
              onClick={() => {
                setFiles([])
              }}
            >
              Cancel
            </button>
            <button
              className={`text-sm text-white rounded-lg px-3 py-2 bg-indigo-500 hover:bg-indigo-600 ${files.length === 0 && "cursor-not-allowed opacity-50 hover:bg-indigo-500"}`}
              disabled={files.length === 0}
              onClick={() => handleUploadFile()}
            >
              Upload the data
            </button>
          </div>
        </main>
      </div>
    </div >
  )
}

export default UploadPricing;