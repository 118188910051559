import localClient from "./client";

// form submit
async function formSubmit(userData, body) {
  const headers = {
    Accept: "application/json",
    Authorization: "Token " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/financial_analysis/calculate_credit_score/`, body, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};


// login 
async function Login(body) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/users/login/`, body, {
      headers,
    });
    data = response.data;
  } catch (e) {
    // If the request was made and server responded with a status code
    // that falls out of the range of 2xx, this will be caught here.
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(e.response.data);
      console.log(e.response.status);
      console.log(e.response.headers);
      error = e.response.data; // Assuming server responds with JSON describing the error
    } else if (e.request) {
      // The request was made but no response was received
      console.log(e.request);
      error = "No response from server";
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', e.message);
      error = e.message;
    }
  }

  return { data, error };
};

export { formSubmit, Login };