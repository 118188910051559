import React, { useEffect, useState } from 'react';
import useFormSubmit from '../network/hooks/useFormSubmit';
import Modal from '../components/Modal';
import { useNavigate } from 'react-router-dom';

function FinancialRatiosForm() {
  const { request: submitForm, isLoading: submitFormLoading } = useFormSubmit();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [score, setScore] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData"));
  // navigate back to login page if no userData
  const handleNavigateBack = () => {
    if (!userData) {
      navigate("/");
    }
  };
  useEffect(() => {
    handleNavigateBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Define default values for all dropdowns in one object
  const defaultDropdownValues = {
    industryOutlook: 'favourable',
    demandSupplyGap: 'satisfactoryDemand',
    productionStrength: 'goodProduction',
    marketingStrength: 'goodMarketing',
    expectedSalesGrowth: '5To10Percent3Years',
    constitutionExistence: 'publicLtdListed',
    managementEducationExperience: 'trainedOver3Years',
    managementIntegrityCommitment: 'beyondDoubt',
    successionPlanning: 'experiencedManagement',
    collateralsCoverage: 'coverageAbove100',
    repaymentPeriod: 'repayment3To5Years',
    creditHistory: 'noDefault3Years',
    complianceLegalRegulatory: 'fullyComplied',
    governmentApprovals: 'allInPlace',
    accountingTransparency: 'noAdverse',
    // Add default values for other dropdowns here
  };

  const initialFormData = {
    entityName: '',
    financialData: {
      currentAssets: '',
      currentLiabilities: '',
      inventory: '',
      // cash: '',
      cashEquivalents: '',
      totalDebt: '',
      totalShareholdersFund: '',
      depreciation: '',
      // longTermBorrowings: '', // If needed
      // shortTermBorrowings: '', // If needed
      totalAssets: '',
      totalOutsideLiabilities: '',
      tangibleNetWorth: '',
      ebit: '',
      financeExpenses: '',
      principalRepayment: '',
      cogs: '',
      averageInventory: '',
      netCreditSales: '',
      averageDebtors: '',
      netCreditPurchases: '',
      averageCreditors: '',
      netProfit: '',
      // capitalEmployed: '',
      // operatingProfit: '',
      netSales: '',
    },
    dropdownValues: defaultDropdownValues, // Merged dropdown values
  };

  const [formData, setFormData] = useState(initialFormData);
  console.log(formData)

  const handleChange = (e, subCategory) => {
    setFormData({
      ...formData,
      dropdownValues: {
        ...formData.dropdownValues,
        [subCategory]: e.target.value,
      },
    });
  };

  const handleChangeInput = (e, subCategory) => {
    const value = e.target.value;

    // 1. Allow numeric values, decimal points, and a negative sign at the beginning
    let numericValue = value.replace(/[^0-9.-]/g, '');

    // 2. Ensure only one negative sign at the beginning
    numericValue = numericValue.replace(/^-/, 'NEGATIVE').replace(/-/g, '').replace(/NEGATIVE/, '-');

    // 3. Ensure only one decimal point and limit to two decimal places (adjustable)
    numericValue = numericValue
      .replace(/(\..*?)\..*/g, '$1') // Allow only one decimal point
      .replace(/(\.\d{2})\d+/g, '$1'); // Limit to two decimal places

    // 4. Format the number with commas, keeping the sign in place
    const isNegative = numericValue.startsWith('-');
    const parts = numericValue.replace('-', '').split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedValue = (isNegative ? '-' : '') + parts.join('.');

    setFormData({
      ...formData,
      financialData: {
        ...formData.financialData,
        [subCategory]: formattedValue,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data, error } = await submitForm(userData, formData)

    if (data && data.success) {
      setScore(data?.data?.score);
      setOpenModal(true);

      const link = document.createElement('a');
      link.href = `http://139.59.21.149:8000/financial_analysis/pdfs${data?.data?.path}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }

    if (error) {
      console.log("error", error)
      alert(error?.response?.data?.message)
    }
  };


  const renderDropdown = (label, name, options) => (
    <div className="dropdown-group mb-4">
      <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
        {label}:
      </label>
      <select
        className="dropdown shadow border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
        name={name}
        id={name}
        value={formData.dropdownValues[name]}
        onChange={(e) => handleChange(e, name)}
        aria-label={label}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="container">
      {/* <div className=' flex justify-between gap-24'> */}
      <div className=" px-4 py-16 flex-1">
        <h1 className="text-4xl font-bold text-center mb-10">Financial Ratios Form</h1>
        <form onSubmit={handleSubmit} className="bg-white shadow-lg border border-[#bcc3d1] border-opacity-50 rounded-xl px-8 pt-6 pb-8">
          <div className="grid grid-cols-2 gap-4 py-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                EntityName
              </label>
              <input
                type="text"
                value={formData?.entityName}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    entityName: e.target.value.replace(' ', '_'),
                  })
                }}
                className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Map through each key in financialData to generate input fields */}
            {Object.keys(formData.financialData).map((key) => (
              <div key={key} className="mb-4">
                <label htmlFor={key} className="block text-gray-700 text-sm font-bold mb-2">
                  {key.split(/(?=[A-Z])/).join(' ').charAt(0).toUpperCase() + key.slice(1)}:
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={formData.financialData[key]}
                  onChange={(e) => handleChangeInput(e, key)}
                  className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  aria-label={key}
                />
              </div>
            ))}
          </div>

          {/* Industry Outlook */}
          {renderDropdown(
            'Industry Outlook',
            'industryOutlook',
            [
              { value: 'unfavourable', label: 'Unfavourable' },
              { value: 'marginallyUnfavorable', label: 'Marginally Unfavorable' },
              { value: 'marginallyFavorable', label: 'Marginally Favorable' },
              { value: 'favourable', label: 'Favourable' },
              { value: 'favourableExportOriented', label: 'Favourable & export-oriented' },
            ]
          )}

          {/* Demand Supply Gap */}
          {renderDropdown(
            'Demand Supply Gap',
            'demandSupplyGap',
            [
              { value: 'excessSupply', label: 'Excess supply than demand' },
              { value: 'marginalDemand', label: 'Marginal demand' },
              { value: 'satisfactoryDemand', label: 'Satisfactory demand' },
              { value: 'goodDemand', label: 'Good Demand' },
              { value: 'scarcity', label: 'Scarcity of Product' },
            ]
          )}

          {/* Production Strength */}
          {renderDropdown(
            'Production Strength',
            'productionStrength',
            [
              { value: 'poorProduction', label: 'Poor production strength' },
              { value: 'inadequateProduction', label: 'Inadequate production strength' },
              { value: 'satisfactoryProduction', label: 'Satisfactory production strength' },
              { value: 'goodProduction', label: 'Good production strength' },
              { value: 'dominance', label: 'Production dominance' },
            ]
          )}

          {/* Marketing Strength */}
          {renderDropdown(
            'Marketing Strength',
            'marketingStrength',
            [
              { value: 'poorMarketing', label: 'Poor customer base and market network' },
              { value: 'inadequateMarketing', label: 'Inadequate customer base and market network' },
              { value: 'satisfactoryMarketing', label: 'Satisfactory customer base and market network' },
              { value: 'goodMarketing', label: 'Good customer base and market network' },
              { value: 'sellersMarket', label: 'Sellers Market' },
            ]
          )}

          {/* Expected Sales Growth */}
          {renderDropdown(
            'Expected Sales Growth',
            'expectedSalesGrowth',
            [
              { value: 'negativeGrowth', label: '< 5% or Negative for consecutively 2-3 years' },
              { value: 'upto5Percent1Year', label: 'up to 5% for 1 year out of 3 years' },
              { value: '5Percent2Years', label: '5 % for consecutively 2 years out of past 3 years' },
              { value: '5To10Percent2Years', label: '5-10% for consecutively in last 2 years out of past 3 years' },
              { value: '5To10Percent3Years', label: '5-10% for consecutively in last 3 years out of past 3 years' },
            ]
          )}

          {/* Constitution & Existence of the Applicant */}
          {renderDropdown(
            'Constitution & Existence of the Applicant',
            'constitutionExistence',
            [
              { value: 'newFirm', label: 'New Proprietorship /Partnership firm/HUF' },
              { value: 'establishedFirm1Year', label: 'Proprietorship /Partnership/Pvt. Ltd firm more than a year old' },
              { value: 'pvtLtd3Years', label: 'Pvt. Ltd company with experience of 3 years' },
              { value: 'ltd3YearsNotListed', label: 'Ltd company with exp. of 3 years but not listed' },
              { value: 'publicLtdListed', label: 'Listed public ltd company' },
            ]
          )}

          {/* Management Education Experience */}
          {renderDropdown(
            'Management Education & Experience',
            'managementEducationExperience',
            [
              { value: 'untrainedStartup', label: 'Not trained and fresh start-up' },
              { value: 'trainedFresh', label: 'Trained but fresh' },
              { value: 'trainedUnder1Year', label: 'Trained with experience of less than a year' },
              { value: 'trainedOver1Year', label: 'Trained with experience of more than 1 year' },
              { value: 'trainedOver3Years', label: 'Trained with experience of more than 3 years' },
            ]
          )}

          {/* Management Integrity Commitment */}
          {renderDropdown(
            'Management Integrity & Commitment',
            'managementIntegrityCommitment',
            [
              { value: 'poorDefaulter', label: 'Poor/willful defaulter' },
              { value: 'marginallyAcceptable', label: 'Marginally acceptable' },
              { value: 'satisfactory', label: 'Satisfactory' },
              { value: 'reliable', label: 'Reliable' },
              { value: 'beyondDoubt', label: 'Beyond doubt' },
            ]
          )}

          {/* Succession Planning */}
          {renderDropdown(
            'Succession Planning',
            'successionPlanning',
            [
              { value: 'notAvailable', label: 'Not available' },
              { value: 'foundFromEmployee', label: 'May be found from an existing employee' },
              { value: 'availableNeedTraining', label: 'Available next line management but need training' },
              { value: 'experiencedManagement', label: 'Experienced next line management' },
            ]
          )}

          {/* Collaterals Coverage */}
          {renderDropdown(
            'Collaterals Coverage%',
            'collateralsCoverage',
            [
              { value: 'coverageBelow10', label: '≤10' },
              { value: 'coverage10To40', label: '10.0-≤40.0' },
              { value: 'coverage40To75', label: '40.01-≤ 75.0' },
              { value: 'coverage75To100', label: '75.0-≤ 100.0' },
              { value: 'coverageAbove100', label: '>100%' },
            ]
          )}

          {/* Repayment Period */}
          {renderDropdown(
            'Repayment Period',
            'repaymentPeriod',
            [
              { value: 'repaymentOver7Years', label: '≥ 7 years' },
              { value: 'repayment6To7Years', label: '6.1-≤7 years' },
              { value: 'repayment5To6Years', label: '5.1-≤6 years' },
              { value: 'repayment3To5Years', label: '3.1-≤5 years' },
              { value: 'repaymentUnder3Years', label: '≤ 3 years' },
            ]
          )}

          {/* Credit History */}
          {renderDropdown(
            'Credit History',
            'creditHistory',
            [
              { value: 'pastDueOver3Months', label: 'Past due over 3 months' },
              { value: 'occasionallyIrregular', label: 'Occasionally Irregular due to interest but repaid' },
              { value: 'noIrregularity1Year', label: 'No irregularity in the past year' },
              { value: 'noDefault3Years', label: 'No Past due/default in past 3 years' },
              { value: 'noDefaultRelatedParty', label: 'No past due/default in any related party' },
            ]
          )}

          {/* Compliance Legal Regulatory */}
          {renderDropdown(
            'Compliance Legal Regulatory',
            'complianceLegalRegulatory',
            [
              { value: 'noSecurityDocument', label: 'Not created security document as per law' },
              { value: 'compliedButGovtDue', label: 'Complied in Creation of securities but Govt. due' },
              { value: 'complied', label: 'Complied' },
              { value: 'fullyComplied', label: 'Fully Complied' },
            ]
          )}

          {/* Government Approvals */}
          {renderDropdown(
            'Government Approvals',
            'governmentApprovals',
            [
              { value: 'approvalsPending', label: 'Yet to obtain approvals' },
              { value: 'majorityPending', label: 'Majority left to start the business' },
              { value: 'minorPending', label: 'Some left which does not affect to start the operation' },
              { value: 'majorObtained', label: 'Major approvals were obtained.' },
              { value: 'allInPlace', label: 'All approval is in place' },
            ]
          )}

          {/* Accounting Transparency */}
          {renderDropdown(
            'Accounting Transparency',
            'accountingTransparency',
            [
              { value: 'adverseImpactAbove20', label: 'Adverse observation having an impact on TNW by 20% or above' },
              { value: 'adverseImpact10To20', label: 'Adverse observation having an impact on TNW by 10% to 20%' },
              { value: 'adverseImpactUpto10', label: 'Adverse observation having an impact on TNW up to 10%' },
              { value: 'minorAdverseComment', label: 'Some adverse comment but does not affect' },
              { value: 'noAdverse', label: 'No adverse observation' },
            ]
          )}

          {/* ... other dropdowns following the same structure */}
          {/* Add the rest of the dropdowns by calling the renderDropdown function with the appropriate parameters */}

          <button type="submit" className="flex gap-2 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline mt-4">
            Submit
            {submitFormLoading &&
              <div>
                <svg className="animate-spin -ml-1 h-5 w-5 text-M01" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
          </button>
        </form>

        <Modal open={openModal} onClose={() => setOpenModal(false)} maxWidth="max-w-[800px]">
          <div className='p-20 text-center bg-ribbons-gif bg-center bg-cover'>
            <h2 className='text-3xl mb-4 font-medium  opacity-50'>Your Score is:</h2>
            <p className='text-6xl font-bold text-blue-500'>{score}</p>
          </div>
        </Modal>
      </div>

      {/* <div className=' max-w-96 w-full h-[100vh] mt-16 flex flex-col  items-center justify-center'>
          <h2 className=' text-4xl font-medium text-center mb-10'>Want to Generate PDF?</h2>

          <button 
          className="flex gap-2 justify-center items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline mt-4"
          onClick={() => navigate('/document-upload')}
          >
              Click here
            </button>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default FinancialRatiosForm;