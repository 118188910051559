import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from '../network/hooks/useLogin';

function Login() {
  const { request: Login, isLoading: loginLoading } = useLogin();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [hidePassword, setHidePassword] = useState(true);

  const handleLoggedInUsers = () => {
    if (userData) {
      navigate("/home");
    }
  }

  useEffect(() => {
    handleLoggedInUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableLoginButton = () => {
    if (!userName || !password) {
      return true;
    } else {
      return false
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    const { data, error } = await Login({
      username: userName,
      password: password,
    });

    if (data) {
      localStorage.setItem("userData", JSON.stringify(data));
      navigate("/scoring-model");
    }

    if (error) {
      setLoginError(error?.response?.data?.message);
    }
  }

  return (
    <main className="bg-white">

      {/* <div className="flex justify-center items-center pt-10">
        <img src={CARGOXBLOGO} alt="CargoXB Logo" />
      </div> */}

      <div style={{ minHeight: "calc(100vh - 95px)" }} className="flex justify-center items-center max-w-[442px] mx-auto">

        {/* Content */}
        <div className="grow px-4 py-8">
          <h1 className="text-3xl text-slate-800 font-bold mb-6">Login! ✨</h1>
          {/* Form */}
          <form onSubmit={handleLogin}>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1" htmlFor="userName">User Name</label>
                <input
                  id="userName"
                  className="border rounded-lg p-2 w-full"
                  type="text"
                  value={userName}
                  onChange={(e) => {
                    setLoginError("");
                    setUserName(e.target.value);
                  }}
                />
              </div>

              <div className="relative">
                <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                <input
                  id="password"
                  className="border rounded-lg p-2 w-full"
                  type={`${hidePassword ? "password" : "text"}`}
                  value={password}
                  onChange={(e) => {
                    setLoginError("");
                    setPassword(e.target.value);
                  }}
                />
                <div
                  className="absolute bottom-1 right-[0] px-4 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setHidePassword(!hidePassword);
                  }}
                >
                  {hidePassword ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5C9.33696 12.5 8.70107 12.2366 8.23223 11.7678C7.76339 11.2989 7.5 10.663 7.5 10C7.5 9.33696 7.76339 8.70107 8.23223 8.23223C8.70107 7.76339 9.33696 7.5 10 7.5C10.663 7.5 11.2989 7.76339 11.7678 8.23223C12.2366 8.70107 12.5 9.33696 12.5 10Z" stroke="#202020" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.66602 9.99984C2.99935 6.58567 6.11268 4.1665 9.99935 4.1665C13.886 4.1665 16.9993 6.58567 18.3327 9.99984C16.9993 13.414 13.886 15.8332 9.99935 15.8332C6.11268 15.8332 2.99935 13.414 1.66602 9.99984Z" stroke="#202020" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M1.66732 4.39183L2.73398 3.3335L16.6673 17.2668L15.609 18.3335L13.0423 15.7668C12.084 16.0835 11.0673 16.2502 10.0007 16.2502C5.83398 16.2502 2.27565 13.6585 0.833984 10.0002C1.40898 8.5335 2.32565 7.24183 3.49232 6.21683L1.66732 4.39183ZM10.0007 7.50016C10.6637 7.50016 11.2996 7.76355 11.7684 8.2324C12.2373 8.70124 12.5007 9.33712 12.5007 10.0002C12.5011 10.284 12.4532 10.5658 12.359 10.8335L9.16732 7.64183C9.43504 7.54765 9.71685 7.49974 10.0007 7.50016ZM10.0007 3.75016C14.1673 3.75016 17.7257 6.34183 19.1673 10.0002C18.4872 11.7278 17.3314 13.2274 15.834 14.3252L14.6507 13.1335C15.8031 12.3363 16.7325 11.2577 17.3507 10.0002C16.677 8.62511 15.631 7.46664 14.3317 6.65642C13.0324 5.8462 11.5319 5.41673 10.0007 5.41683C9.09232 5.41683 8.20065 5.56683 7.36732 5.8335L6.08398 4.5585C7.28398 4.04183 8.60898 3.75016 10.0007 3.75016ZM2.65065 10.0002C3.32435 11.3752 4.37029 12.5337 5.66959 13.3439C6.96889 14.1541 8.46943 14.5836 10.0007 14.5835C10.5757 14.5835 11.1423 14.5252 11.6673 14.4085L9.76732 12.5002C9.18745 12.438 8.64634 12.1792 8.23396 11.7669C7.82159 11.3545 7.56281 10.8134 7.50065 10.2335L4.66732 7.39183C3.84232 8.10016 3.15065 8.9835 2.65065 10.0002Z" fill="#202020" />
                    </svg>
                  }
                </div>
              </div>
              <p className="text-xs text-[red]">{loginError}</p>
            </div>
            <div className="flex items-center justify-end mt-6 gap-2">
              <button
                className={` rounded-lg p-2 flex gap-2 items-center justify-center bg-indigo-500 hover:bg-indigo-600 text-white sm:max-w-[200px] sm:w-full ${disableLoginButton() && "cursor-not-allowed opacity-70 hover:bg-indigo-500"}`}
                disabled={disableLoginButton()}
                type="submit"
              >
                Login
                {loginLoading && <span className='pl-2'><svg className=" animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg></span>}
              </button>
            </div>
          </form>
        </div>

      </div>

    </main>
  );
}

export default Login;