import { Route, Routes } from "react-router-dom";
import "./App.css"
import NewScoringModel from "./pages/NewScoringModel";
import Login from "./pages/Login";
import DocumentUpload from "./pages/DocumentUpload";
import Home from "./pages/Home";

function App() {
  return (
    <div className="App bg-dot bg-repeat">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/scoring-model" element={<NewScoringModel />} />
        <Route path="/document-upload" element={<DocumentUpload />} />
      </Routes>
    </div>
  );
}

export default App;
