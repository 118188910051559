import { useCallback, useState } from "react";
import { formSubmit } from "../api";
import { useNavigate } from "react-router-dom";

const useFormSubmit = () => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const request = useCallback(async (userData, body) => {
    setLoading(true);
    const { data, error } = await formSubmit(userData, body);
    setLoading(false);

    if (error && error?.response?.status === 401) {
      localStorage.setItem("userData", null);
      navigate("/");
    }

    return { data, error };
  });

  return { isLoading, request };
};

export default useFormSubmit;
